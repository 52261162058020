export enum AppEnv {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
}

export const PUBLIC_SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL!;
export const PUBLIC_SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!;
export const PUBLIC_SITE_URL = process.env.NEXT_PUBLIC_SITE_URL!;
export const PUBLIC_APP_ENV: AppEnv = process.env.NEXT_PUBLIC_APP_ENV as AppEnv || AppEnv.PRODUCTION;
export const PUBLIC_S3_PUBLIC_PREFIX = process.env.NEXT_PUBLIC_S3_PUBLIC_PREFIX!;

export const PUBLIC_AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
export const PUBLIC_AMPLITUDE_SAMPLE_RATE = parseFloat(process.env.NEXT_PUBLIC_AMPLITUDE_SAMPLE_RATE || '0');
